@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'SF Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

@layer components {
  .card {
    border-radius: theme('borderRadius.lg');
    padding: theme('spacing.4');
  }
  /* ... */
}